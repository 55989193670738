/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KanonWebApiInformation
 */
export interface KanonWebApiInformation {
    /**
     * 
     * @type {number}
     * @memberof KanonWebApiInformation
     */
    sagaId: number;
    /**
     * 
     * @type {string}
     * @memberof KanonWebApiInformation
     */
    webApiVersion: string;
}

export function KanonWebApiInformationFromJSON(json: any): KanonWebApiInformation {
    return KanonWebApiInformationFromJSONTyped(json, false);
}

export function KanonWebApiInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KanonWebApiInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sagaId': json['sagaId'],
        'webApiVersion': json['webApiVersion'],
    };
}

export function KanonWebApiInformationToJSON(value?: KanonWebApiInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sagaId': value.sagaId,
        'webApiVersion': value.webApiVersion,
    };
}


