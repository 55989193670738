/* tslint:disable */
/* eslint-disable */
/**
 * Saga API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyName: string;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    contactId: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    countryName: string;
    /**
     * 
     * @type {Date}
     * @memberof Account
     */
    creationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Account
     */
    modificationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    validityTime: number;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    accessNumber: number;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    cropProduction: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    riskManagement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    hasCleax: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    hasNettingAuto: boolean;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    userWsdId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    userWsdName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    activationStatus: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    currentVersion?: string | null;
}

export function AccountFromJSON(json: any): Account {
    return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': json['token'],
        'companyId': json['companyId'],
        'companyName': json['companyName'],
        'contactId': json['contactId'],
        'contactName': json['contactName'],
        'countryName': json['countryName'],
        'creationDate': (new Date(json['creationDate'])),
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
        'validityTime': json['validityTime'],
        'accessNumber': json['accessNumber'],
        'cropProduction': json['cropProduction'],
        'riskManagement': json['riskManagement'],
        'hasCleax': json['hasCleax'],
        'hasNettingAuto': json['hasNettingAuto'],
        'userWsdId': !exists(json, 'userWsdId') ? undefined : json['userWsdId'],
        'userWsdName': !exists(json, 'userWsdName') ? undefined : json['userWsdName'],
        'activationStatus': json['activationStatus'],
        'currentVersion': !exists(json, 'currentVersion') ? undefined : json['currentVersion'],
    };
}

export function AccountToJSON(value?: Account | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'companyId': value.companyId,
        'companyName': value.companyName,
        'contactId': value.contactId,
        'contactName': value.contactName,
        'countryName': value.countryName,
        'creationDate': (value.creationDate.toISOString()),
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'validityTime': value.validityTime,
        'accessNumber': value.accessNumber,
        'cropProduction': value.cropProduction,
        'riskManagement': value.riskManagement,
        'hasCleax': value.hasCleax,
        'hasNettingAuto': value.hasNettingAuto,
        'userWsdId': value.userWsdId,
        'userWsdName': value.userWsdName,
        'activationStatus': value.activationStatus,
        'currentVersion': value.currentVersion,
    };
}


