import {
  Formik,
  FormikProps,
  FormikHelpers as FormikActions,
  FormikErrors,
} from "formik";
import React from "react";
import {
  AccountApi,
  ShakaApi,
  WapagriApi,
  Account,
  ActionResult,
  Result,
} from "../api";
import { CancelButton, CheckboxField, SubmitButton } from "../components/form";
import { useApi, useNavigator } from "../hooks";
import {
  DateField,
  SuggestField,
  NumericField,
} from "../components/controls/Controls";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Grid } from "@material-ui/core";
import { Label } from "@progress/kendo-react-labels";
import { useParams } from "react-router-dom";

interface dbObject {
  code: string;
  name: string;
}

export interface IAccountManagerFormState {
  id: number;
  companyId: string | undefined;
  companyName: string;
  contactId: string;
  contactName: string;
  countryName: string;
  creationDate: Date;
  validityTime: number;
  accessNumber: number;
  riskManagement: boolean;
  cropProduction: boolean;
  hasCleax: boolean;
  hasNettingAuto: boolean;
  userWsdId: string | undefined | null;
  userWsdName: string | undefined | null;
  activationStatus: boolean;
}

interface ICompany extends dbObject {
  companyId: string;
  companyName: string;
}

interface IContact extends dbObject {
  contactId: string;
  contactName: string;
  countryName: string;
}

interface IUserWsd extends dbObject {
  userWsdId: string;
}

const CompanySuggest = SuggestField<IAccountManagerFormState, ICompany>(
  "companyId",
  "companyId",
  "Société : "
);

const ContactSuggest = SuggestField<IAccountManagerFormState, IContact>(
  "contactId",
  "contactId",
  "Contact : "
);

const UserWsdSuggest = SuggestField<IAccountManagerFormState, IUserWsd>(
  "userWsdId",
  "userWsdId",
  "Compte WSD associé : "
);

interface IFormFormContentProps {
  formikProps: FormikProps<IAccountManagerFormState>;
}

const FormikFormContent = (props: IFormFormContentProps) => {
  const { formikProps } = props;
  const shakaApi = useApi(ShakaApi);
  const wapagriApi = useApi(WapagriApi);
  const [companies, setCompanies] = React.useState<ICompany[]>([]);
  const [contacts, setContacts] = React.useState<IContact[]>([]);
  const [usersWsd, setUsersWsd] = React.useState<IUserWsd[]>([]);
  const navigator = useNavigator();

  React.useEffect(() => {
    const getCompanies = async () => {
      let allCompanies = await shakaApi.shakaGetCompaniesGet();
      setCompanies(
        allCompanies.map((c) => ({
          companyId: c.companyId.toString(),
          companyName: c.name,
          name: c.name,
          code: c.companyId.toString(),
        }))
      );
    };
    getCompanies();
  }, [shakaApi]);

  React.useEffect(() => {
    const getContacts = async () => {
      if (formikProps.values.companyId === "") {
        setContacts([]);
        return;
      }
      let contactsByCompany = await shakaApi.shakaGetContactsByCompanyGet(
        Number(formikProps.values.companyId)
      );
      setContacts(
        contactsByCompany.map((c) => ({
          contactId: c.contactId.toString(),
          contactName: c.lastName + " " + c.firstName,
          countryName: c.country != null ? c.country.name : "",
          name: c.lastName + " " + c.firstName,
          code: c.contactId.toString(),
        }))
      );
    };
    getContacts();
  }, [shakaApi, formikProps.values.companyId]);

  React.useEffect(() => {
    const getUsersWsd = async () => {
      let users = await wapagriApi.wapagriGetUsersGet();
      setUsersWsd(
        users.map((c) => ({
          userWsdId: c.id.toString(),
          name: c.lastName + " " + c.firstName,
          code: c.id.toString(),
        }))
      );
    };
    getUsersWsd();
  }, [wapagriApi]);

  return (
    <form
      className="k-form k-form-horizontal"
      onSubmit={formikProps.handleSubmit}
    >
      <Grid container spacing={1}>
        <Grid item md={6} lg={4}>
          <Card>
            <CardHeader>
              <CardTitle>Le compte</CardTitle>
            </CardHeader>
            <CardBody>
              <DateField stateKey="creationDate" label="Date de création : " />
              <CompanySuggest
                values={companies}
                disabled={comeFromModif === "edit" ? true : false}
                onValueChanged={(a) => {
                  formikProps.setFieldValue(
                    "companyName",
                    a?.companyName || ""
                  );
                }}
              />
              <ContactSuggest
                values={contacts}
                onValueChanged={(a) => {
                  formikProps.setFieldValue(
                    "contactName",
                    a?.contactName || ""
                  );
                  formikProps.setFieldValue(
                    "countryName",
                    a?.countryName || ""
                  );
                }}
              />
              <NumericField
                stateKey="validityTime"
                label="Durée de validité : "
              />
              <UserWsdSuggest
                values={usersWsd}
                onValueChanged={(a) => {
                  formikProps.setFieldValue("userWsdName", a?.name || "");
                }}
              />
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader>
              <CardTitle>La licence</CardTitle>
            </CardHeader>
            <CardBody>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Label>Univers à activer</Label>
                </Grid>
                <Grid item xs={12}>
                  <CheckboxField
                    label="Production végétale"
                    stateKey="cropProduction"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxField
                    label="Gestion de position"
                    stateKey="riskManagement"
                  />
                </Grid>

                {formikProps.values.riskManagement && (
                  <Grid item xs={12}>
                    <CheckboxField label="CleaX" stateKey="hasCleax" />
                  </Grid>
                )}
                {formikProps.values.riskManagement && (
                  <Grid item xs={12}>
                    <CheckboxField
                      label="Netting auto"
                      stateKey="hasNettingAuto"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <NumericField
                    stateKey="accessNumber"
                    label="Nombre d'accès : "
                    allowNegative={false}
                    allowDecimal={false}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      <Grid container justify="flex-end" className="mt-4">
        <CancelButton
          disabled={formikProps.isSubmitting}
          text="Annuler"
          onClick={() => navigator.goBack()}
        />
        <SubmitButton disabled={formikProps.isSubmitting} text="Valider" />
      </Grid>
    </form>
  );
};

let comeFromModif: string;

export const AccountManager = () => {
  const params: any = useParams();
  let { accountId, action } = params;
  const navigator = useNavigator();
  const accountApi = useApi(AccountApi);
  React.useEffect(() => {
    const getCompagnyData = async () => {
      if (
        accountId !== "undefined" &&
        accountId !== null &&
        accountId !== undefined
      ) {
        const company = await accountApi.accountGetAccountByIdIdGet(accountId);
        setCompanyData(company);
      }
    };
    getCompagnyData();
  }, [accountApi, accountId]);
  comeFromModif = action;
  const [companyData, setCompanyData] = React.useState<Account>();

  let initialValue: IAccountManagerFormState = {
    id: 0,
    companyId: "",
    companyName: "",
    contactId: "",
    contactName: "",
    countryName: "",
    creationDate: new Date(),
    validityTime: 15,
    accessNumber: 1,
    riskManagement: false,
    cropProduction: false,
    hasCleax: false,
    hasNettingAuto: false,
    userWsdId: "",
    userWsdName: "",
    activationStatus: true,
  };
  if (companyData) {
    initialValue = {
      id: accountId,
      companyId: action === "edit" ? companyData.companyId.toString() : "",
      companyName: action === "edit" ? companyData.companyName : "",
      contactId: action === "edit" ? companyData.contactId.toString() : "",
      contactName: action === "edit" ? companyData.contactName : "",
      countryName: companyData.countryName,
      creationDate: companyData.creationDate,
      validityTime: companyData.validityTime,
      accessNumber: companyData.accessNumber,
      riskManagement: companyData.riskManagement,
      cropProduction: companyData.cropProduction,
      hasCleax: companyData.hasCleax,
      hasNettingAuto: companyData.hasNettingAuto,
      userWsdId: companyData.userWsdId?.toString(),
      userWsdName: companyData.userWsdName?.split("-").pop(),
      activationStatus: companyData.activationStatus,
    };
  }
  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize={true}
      onSubmit={async (
        formState: IAccountManagerFormState,
        formikActions: FormikActions<IAccountManagerFormState>
      ) => {
        try {
          const account: Account = {
            companyId: Number(formState.companyId),
            token: "",
            companyName: formState.companyName,
            contactId: Number(formState.contactId),
            contactName: formState.contactName,
            countryName: formState.countryName,
            creationDate: formState.creationDate,
            validityTime: formState.validityTime,
            accessNumber: formState.accessNumber,
            cropProduction: formState.cropProduction,
            hasCleax: formState.riskManagement ? formState.hasCleax : false,
            hasNettingAuto: formState.riskManagement
              ? formState.hasNettingAuto
              : false,
            riskManagement: formState.riskManagement,
            userWsdId:
              formState.userWsdId !== "" ? Number(formState.userWsdId) : null,
            userWsdName: formState.userWsdName,
            activationStatus: initialValue.activationStatus,
          };
          let res: ActionResult;
          if (action === "edit") {
            res = await accountApi.accountEditAccountPost({
              ...account,
              id: formState.id,
            });
          } else {
            res = await accountApi.accountSaveAccountPost(account);
          }

          switch (res.result) {
            case Result.Ok:
              navigator.goToAccountListing();
              break;
            case Result.Error:
              formikActions.setFieldError(
                "contactId",
                res.domainExceptionName?.toString()
              );
              break;
          }
        } catch (error) {
          formikActions.setFieldError("contactId", "Une erreur s'est produite");
          return;
        }
      }}
      validate={(accountManagerFormState: IAccountManagerFormState) => {
        const errors: FormikErrors<IAccountManagerFormState> = {};
        if (accountManagerFormState.creationDate)
          if (accountManagerFormState.companyId === "") {
            errors.companyId = "Obligatoire";
          }
        if (accountManagerFormState.contactId === "") {
          errors.contactId = "Obligatoire";
        }
        if (accountManagerFormState.validityTime <= 0) {
          errors.validityTime = "Doit être positif";
        }
        if (accountManagerFormState.accessNumber <= 0) {
          errors.accessNumber = "Doit être positif";
        }
        if (
          accountManagerFormState.riskManagement &&
          (accountManagerFormState.userWsdId === "" ||
            accountManagerFormState.userWsdId === undefined)
        ) {
          errors.userWsdId =
            "Veuillez renseigner un compte WSD associé, afin que la récupération des cours soit automatique";
        }
        return errors;
      }}
    >
      {(formikProps) => <FormikFormContent formikProps={formikProps} />}
    </Formik>
  );
};
